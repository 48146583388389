<template>
  <content-with-sidebar class="cws-container cws-sidebar-right blog-wrapper"
    ><b-progress v-if="searching" :value="barValue" max="100" />
    <!-- content -->
    <div v-if="!searching" class="blog-detail-wrapper">
      <b-row>
        <!-- blogs -->
        <b-col cols="12">
          <b-alert variant="danger" :show="kbDetail.results.inactive">
            <h4 class="alert-heading">
              Article Inactive
            </h4>
            <div class="alert-body">
              <span>this article is inactive</span>
            </div>
          </b-alert>
          <b-card
            ><h2>
              {{ kbDetail.results.title }}
            </h2>
            <b-media no-body>
              <b-media-body>
                <small class="text-muted"
                  >Last Modified:
                  {{ kbDetail.results.timeModified | moment("MMMM Do, YYYY") }}
                  by {{ kbDetail.results.lastModifiedBy }}</small
                >
                <b-badge pill class="ml-75 mr-75">
                  {{ kbDetail.results.category }}
                </b-badge>
              </b-media-body>
            </b-media>
            <div class="my-1" />
            <!-- eslint-disable vue/no-v-html -->
            <!--  <div class="blog-content" v-html="kbDetail.results.body" /> -->
            <div class="body-container">
              <div class="body-content" v-html="kbDetail.results.body"></div>
            </div>
            <!-- eslint-enable -->
            <hr class="my-1" />

            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="d-flex align-items-center">
                  <feather-icon size="21" icon="EyeIcon" class="text-body" />
                  <div class="text-body ml-50">
                    {{ kFormatter(kbDetail.results.pageView) || 1 }}
                  </div>
                </div>
                <b-button
                  variant="outline-secondary"
                  :to="{
                    name: 'pages-knowledge-base-edit',
                    params: { id: articleId },
                  }"
                  class="ml-1"
                >
                  Edit
                </b-button>
                <b-button
                  v-if="kbDetail.queryCount > 0"
                  variant="outline-secondary"
                  class="ml-1"
                  @click="toggleArticleStatus()"
                >
                  <span v-if="!kbDetail.results.inactive">Disable</span
                  ><span v-if="kbDetail.results.inactive">Enable</span>
                </b-button>
              </div>
            </div>
          </b-card>
        </b-col>
        <!--/ blogs -->
      </b-row>
      <!--/ blogs -->
    </div>
    <!--/ content -->
    <!-- sidebar -->
    <div
      slot="sidebar"
      class="body-container2 blog-sidebar py-2 py-lg-0"
    >
      <div class="body-content">
        <!-- table of contents -->
        <br /><div v-if="headers[0]" class="table-of-contents mb-3">
  <div class="section-label mb-2"><b>Table of Contents</b></div>
  <ul>
    <li v-for="header in headers">
      <a
        v-if="header.level == 2"
        :href="header.url"
        @click.prevent="scrollToHeader(header.url)"
        :style="{ fontSize: `${1.5 - header.level * 0.2}rem` }"
      >
        {{ header.text }}
      </a>
      <a
      v-if="header.level > 2"
        :href="header.url"
        @click.prevent="scrollToHeader(header.url)"
        :style="{ fontSize: `${1.5 - header.level * 0.2}rem`, marginLeft: `${header.level * 4}px` }"
      >
        {{ header.text }}
      </a>
    </li>
  </ul>
</div>

        <h6 class="section-label">
          Article Info
        </h6>

        <div
          v-if="kbDetail.results.author"
          class="d-flex align-items-center mt-2"
        >
          {{ kbDetail.results.author }}
        </div>
        <div v-if="kbDetail.results.author" class="font-small-1 mb-1">
          Author
        </div>
        <div
          v-if="kbDetail.results.lastModifiedBy"
          class="d-flex align-items-center"
        >
          {{ kbDetail.results.lastModifiedBy }}
        </div>
        <div v-if="kbDetail.results.lastModifiedBy" class="font-small-1 mb-1">
          Last Modified By
        </div>
        <span v-if="kbDetail.results.customer.length > 0">
          <div
            v-for="company in kbDetail.results.customer"
            :key="company.identifier"
            class="d-flex align-items-center"
          >
            <router-link :to="'/knowledge-base/company/' + company.identifier">
              {{ company.name }}
            </router-link>
          </div>
          <div
            v-if="kbDetail.results.customer[0].name"
            class="font-small-1 mb-1"
          >
            Companies
          </div>
        </span>
      </div>

      <!-- recent posts -->
      <div>
        <h6 class="section-label mt-3">
          Recent Posts
        </h6>
        <b-card v-for="recentpost in top10" :key="recentpost.UID" class="mb-1 mr-1">
          <router-link
            :to="{
              name: 'pages-knowledge-base-question',
              params: { category: 'KBCAT-0002', slug: recentpost.UID },
            }"
          >
            <h6 class="mb-0">
              {{ recentpost.title }}
            </h6>
          </router-link>
          <span class="text-muted mb-0">
            {{ recentpost.lastModifiedBy }}
          </span>
        </b-card>
      </div>
      <!--/ recent posts -->
    </div>
  </content-with-sidebar>
</template>

<script>
import {
  BFormInput,
  BMedia,
  BMediaBody,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BCard,
  BRow,
  BCol,
  BBadge,
  BProgress,
  BButton,
  BAlert,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { kFormatter } from "@core/utils/filter";
import ContentWithSidebar from "@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue";

export default {
  components: {
    BFormInput,
    BMedia,
    BMediaBody,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BBadge,
    BProgress,
    BButton,
    BAlert,
    ContentWithSidebar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      searching: true,
      barValue: 0,
      timer: 0,
      kbDetail: {
        queryTime: {
          value: "",
          DisplayHint: 2,
          DateTime: "",
        },
        queryCount: 1,
        results: {
          ID: 0,
          UID: "",
          timeModified: "",
          title: "",
          subtitle: "",
          category: "",
          customer: [{ identifier: "", name: "" }],
          author: "",
          body: "",
          body2: "",
          lastModifiedBy: "",
          kbCatID: "",
          inactive: 0,
          pageView: 0,
        },
      },
      top10: {},
    };
  },
  watch: {
    "$route.params.slug": {
      handler() {
        this.fetchData();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.timer = setInterval(() => {
      this.barValue = (this.barValue + 5) % 100;
    }, 600);
  },
  async created() {
    await this.fetchData();
  },computed: {
  headers() {
    const headers = [];
    const temp = document.createElement("div");
    temp.innerHTML = this.kbDetail.results.body;

    // Select all header tags in their order of occurrence
    const elements = temp.querySelectorAll("h1, h2, h3, h4, h5, h6");

    // Iterate through the header tags
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      const id = `heading-${headers.length}`;
      element.id = id;
      element.style = "top: -100px;";
      headers.push({
        level: parseInt(element.tagName.substring(1)), // Using tagName to determine the level
        text: element.textContent,
        url: `#${id}`,
      });
    }

    this.kbDetail.results.body = temp.innerHTML;
    return headers;
  }
},
  methods: {
    scrollToHeader(url) {
      const targetElement = document.querySelector(url);
      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
          offset: -500,
        });
      }
    },
    async fetchData() {
      this.searching = true;
      this.articleId = this.articleId = this.$route.params.slug;
      this.$emit("changeRouteTitle", this.articleId);
      try {
        const [kbDetailRes] = await Promise.all([
          this.$http.get(
            `https://asi-api2.azure-api.net/kb/article?id=${this.articleId}&key=7d959c93d0ed474abcdf04804842848a`
          ),
        ]);

        this.kbDetail = kbDetailRes.data;
        await this.getLast10();
        this.searching = false;
      } catch (error) {
        console.log(error);
      } finally {
      }
    },

    kFormatter,

    toggleArticleStatus() {
      const action = this.kbDetail.results.inactive ? "enable" : "disable";

      this.$http
        .get(
          `https://asi-api2.azure-api.net/kb/article/${this.articleId}/${action}?key=7d959c93d0ed474abcdf04804842848a`
        )
        .then(() => {
          this.kbDetail.results.inactive = !this.kbDetail.results.inactive;
        });
    },

    async getLast10() {
      this.$http
        .get(
          "https://asi-qbeinvoice.search.windows.net/indexes/articles/docs?api-version=2020-06-30&search=*&%24top=5&%24orderby=timeModified%20desc",
          {
            headers: {
              "Content-Type": "application/json",
              "api-key": "80BE6A3D033613C6B21312DA801985E6",
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.top10 = response.data.value;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-blog.scss";
</style>

<style lang="scss">
.blog-content {
  img {
    width: auto;
    max-width: 95%;
  }
}
</style>
<style>
.table-of-contents ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.table-of-contents li {
  margin-bottom: 5px;
}
.content h2 {
  margin-top: 0;
}
.body-container {
  max-height: calc(
    100vh - 400px
  ); /* set the maximum height to the height of the viewport minus 100 pixels */
  overflow-y: scroll; /* add a vertical scroll bar if the content overflows the container */
  border: 0px solid #ccc; /* add a border for visual separation */
  padding: 5px; /* add some padding to the container */
}
.body-container img {
  max-width: 90%;
}
.body-container2 {
  max-height: calc(
    100vh - 250px
  ); /* set the maximum height to the height of the viewport minus 100 pixels */
  overflow-y: auto; /* add a vertical scroll bar if the content overflows the container */
  border: 0px solid #ccc; /* add a border for visual separation */
  padding: 0px; /* add some padding to the container */
}

.body-content {
  margin: 0; /* remove any default margin */
  padding: 0; /* remove any default padding */
}

li > img:only-child {
  display: block;
}
li:has(> img:only-child) {
  list-style: none;
}
</style>
